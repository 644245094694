// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  padding-left: 12px;
  padding-right: 12px;
  /* width: 100%; */
  display: block;
}

.text-container {
  border-top: 1px solid #d0d2d3;
}

.text-container span {
  color: #526B7A;
  opacity: 0.8;
  position: relative;
  height: 31px;
  display: flex;
  align-items: center;
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/multi-select-footer/multi-select-footer.component.scss","webpack://./../../___CICD%20JOBS___/Deploy%20ESP%20to%20devN/src/app/components/multi-select-footer/multi-select-footer.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,mBAAA;EACA,iBAAA;EACA,cAAA;ACCF;;ADCA;EACE,6BAAA;ACEF;;ADAA;EACE,cAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;ACGF","sourcesContent":[":host{\n  padding-left: 12px;\n  padding-right: 12px;\n  /* width: 100%; */\n  display: block;\n}\n.text-container{\n  border-top: 1px solid #d0d2d3;\n}\n.text-container span{\n  color: #526B7A;\n  opacity: 0.8;\n  position: relative;\n  height: 31px;\n  display: flex;\n  align-items: center;\n  font-size: 12px;\n}\n",":host {\n  padding-left: 12px;\n  padding-right: 12px;\n  /* width: 100%; */\n  display: block;\n}\n\n.text-container {\n  border-top: 1px solid #d0d2d3;\n}\n\n.text-container span {\n  color: #526B7A;\n  opacity: 0.8;\n  position: relative;\n  height: 31px;\n  display: flex;\n  align-items: center;\n  font-size: 12px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
