import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {appSettings, loadPlanDetailV2Url} from "../../app-settings";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Action} from "@ngrx/store";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Observable, of} from "rxjs";
import {catchError, map, mergeMap, switchMap} from "rxjs/operators";
import {
  AppActionTypes,
  EntityListReceived,
  EntityListRequest,
  PlanListReceived,
  PlanListRequest,
  PlanDetailReceived,
  PlanDetailRequest,
  EditPlanDetailRequest,
  EditPlanDetailReceived,
  SavePlanDetailComplete,
  DeletePlansSuccess,
  RefreshPlanCacheRequest,
  CacheSyncSuccess,
  ConvertPlanSuccess,
  CheckPlanNameExistsReceived,
  GetMuStaffing,
  GetMuStaffingSuccess,
  SaveAsPlanReceived,
  MuAndMusetReceived,
  CreatePlanSubmitted,
  EditMUDistSuccess,
  DiscardMUDist,
  DiscardMUDistSuccess,
  ApplyStaffingData,
  ApplyStaffingDataSuccess,
  ApplyStaffingDataError,
  LoadPlanDetail,
  ApplyMuParams,
  ApplyMuParamsSuccess,
  ApplyMuParamsError,
  SavePlanSettings,
  SavePlanSettingsSuccess,
  SavePlanSettingsError,
  ApplyFTERequired,
  ApplyFTERequiredSuccess,
  ApplyFTERequiredError,
  EditEntitySettings,
  EditEntitySettingsSuccess,
  EditMUDistV2,
  ExportEveryCtSuccess,
  MultiEditPlanDetail,
  LoadPlanDetailV2,
  PlanDetailReceivedV2,
  MultiEditPlanDetailV2,
  GetCTSettings,
  GetCTSettingsSuccess,
  GetCTSettingsFailed,
  ApplyCTSettings,
  ApplyCTSettingsSuccess
} from "../actions";
import { PlanListResponse, PlanDetailResponse, PlanNameExists, MuStaffingResponse, DiscardMuDistRequest, DiscardMuStffingResponse, EditEntitySettingsDto, EditMuDistRequestV2 } from "src/app/models/plan";
import { PlanHelper } from "src/app/helpers/plan-helper";
import { PlanSettingsComponent } from "src/app/pages/plan/plan-settings/plan-settings.component";
import { TasksService } from "src/app/services/tasks.service";

export interface EntityListResponse {
    entities?: Array<any>;
    constructor(data: any);
}


@Injectable()
export class PlanEffects {

    constructor(private http: HttpClient, private actions$: Actions,private tasksService: TasksService) { }

    entityList$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(AppActionTypes.GetEntityList),
        map((action: EntityListRequest) => action.payload),
        mergeMap((payload: any) => {
            // const action = payload.action;
            const entityListUrl = environment.espEndpointBaseUrl + appSettings.resourceLocations.entityList;
            return this.http.get(entityListUrl + `?${payload}`)
                .pipe(
                    map((data: EntityListResponse) => (new EntityListReceived(data.entities))),
                    catchError(() => of({ type: AppActionTypes.EntityListReceived, payload: null })),
                )
        })
    ));

    planList$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(AppActionTypes.GetPlanList),
        map((action: PlanListRequest) => action.payload),
        mergeMap((payload: any) => {
            const planListUrl = environment.espEndpointBaseUrl + appSettings.resourceLocations.planList;
            let urlParameters = encodeURI(Object.entries(payload).map(e => e.join("=")).join("&"));
            return this.http.get(planListUrl + `?${urlParameters}`)
                .pipe(
                    // If successful, dispatch success action with result
                    map((data: PlanListResponse) => {
                        return new PlanListReceived(data,payload);
                    }
                    ),
                    // If request fails, dispatch failed action
                    catchError((err) => of({ type: AppActionTypes.PlanListRetrieveFailed, payload: err })),
                )
        })
    ));

    planDetail$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(AppActionTypes.GetPlanDetail),
        map((action: PlanDetailRequest) => action.payload),
        mergeMap((payload: any) => {

            const planOid = encodeURI(payload.planId);
            let resource = appSettings.resourceLocations.planDetail(planOid);
            let planDetailUrl = environment.espEndpointBaseUrl + resource;
            if(payload.oid){
              planDetailUrl+="/"+payload.oid;
            }

            let headers = new HttpHeaders().append('esp-plan-sessionId', PlanHelper.getPlanDetailSessionId(planOid));
            let options = { headers: headers };
            const params = {interval: payload.interval, entity_type: payload.entity_type, murollup: payload.murollup};
            let urlParameters = encodeURI(Object.entries(params).map(e => e.join("=")).join("&"));
            // let urlParameters = encodeURI("interval=" + payload.interval);
            let url = planDetailUrl + `?${urlParameters}`;
            // let url = "http://localhost:3000/entities"  + `?${urlParameters}`;
            return this.http.get(url, options)
                .pipe(
                    // If successful, dispatch success action with result
                    map((data: PlanDetailResponse) => {
                        data.interval = payload.interval;
                        data.planId = planOid;
                        data.selectedType = payload.entity_type;
                        data.selectedOid = payload.oid;

                        /*data.entityType = "EG"
                        data.ctInfo = [
                          {id:'1000',name:'CT: Red',oid:'200-200-200'},
                          {id:'2000',name:'CT: Green',oid:'300-200-200'},
                          {id:'3000',name:'CT: Blue',oid:'400-200-200'},
                          {id:'4000',name:'CT: Orange',oid:'500-200-200'},
                          {id:'5000',name:'CT: Yellow',oid:'600-200-200'},
                          {id:'6000',name:'CT: Black',oid:'700-200-200'},
                          ]*/
                        return new PlanDetailReceived(data);
                    }
                    ),
                    // If request fails, dispatch failed action
                    catchError((error:any) => {
                        if(error.status === 410){
                            return of({ type: AppActionTypes.PlanNotExist, payload: null });
                        }
                        return of({ type: AppActionTypes.PlanDetailReceived, payload: null });

                }),
                )
        })
    ));

  loadPlanDetail$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AppActionTypes.LoadPlanDetail),
    mergeMap((action: LoadPlanDetail) => {
      let payload = action.payload;
      const planOid = encodeURI(payload.planId);
      let resource = appSettings.resourceLocations.loadPlanDetail(planOid);
      let planDetailUrl = environment.espEndpointBaseUrl + resource;
      if (payload.oid) {
        planDetailUrl += "/" + payload.oid;
      }

      let headers = new HttpHeaders().append('esp-plan-sessionId', PlanHelper.getPlanDetailSessionId(planOid));
      let options = {headers: headers};

      let urlParameters = encodeURI("interval=" + payload.interval + "&histdata=" + payload.histdata);
      let url = planDetailUrl + `?${urlParameters}`;

      return this.tasksService.createTask("GET", url, options).pipe(
        map((data) => {
          data.interval = payload.interval;
          data.planId = planOid;
          return new PlanDetailReceived(data)
        }),
        catchError((error)=>{
          if(error.status === 410){
            return of({ type: AppActionTypes.PlanNotExist, payload: null });
          }
          return of({ type: AppActionTypes.PlanDetailReceived, payload: null });
        }))
    }
  )));

    editPlanDetail$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(AppActionTypes.EditPlanDetail),
        map((action: EditPlanDetailRequest) => action.payload),
        mergeMap((payload: any) => {
            // const planOid = encodeURI(payload.planId);
            // let resource = appSettings.resourceLocations.planDetail(planOid);
            const planDetailUrl = environment.espEndpointBaseUrl + appSettings.resourceLocations.planList;

            // let urlParameters = encodeURI("interval=" + payload.interval);
            let url = planDetailUrl;// + `?${urlParameters}`;
            //if(url) return Observable.of(new EditPlanDetailReceived({editValues:[], interval: payload.interval, request: payload}));
            let headers = new HttpHeaders().append('esp-plan-sessionId', PlanHelper.getPlanDetailSessionId(payload.planId));
            let options = { headers: headers };
            return this.http.put(url, payload, options).pipe( // If successful, dispatch success action with result
                map((data: PlanDetailResponse) => {
                    data.interval = payload.interval;
                    data.request = payload;
                    return new EditPlanDetailReceived(data);
                }
                ),
                // If request fails, dispatch failed action
                catchError((e) => {
                    if (payload.planParameterLabel === PlanSettingsComponent.NEW_HIRE_TRAINING_PERIOD)
                        return of({ type: AppActionTypes.EditNewHireTrainingError, payload: e });
                    else
                        return of({ type: AppActionTypes.EditPlanDetailError, payload: {error:e, request:payload} });
                })
            );
        })
    ));

    savePlanDetail$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(AppActionTypes.SavePlanDetail),
        map((action: any) => action.payload),
        mergeMap((payload: any) => {
            let planId = payload;
            const planDetailUrl = environment.espEndpointBaseUrl + appSettings.resourceLocations.planList;
            let url = planDetailUrl + "/" + planId;
            let headers = new HttpHeaders().append('esp-plan-sessionId', PlanHelper.getPlanDetailSessionId(planId));
            let options = { headers: headers };
            return this.http.put(url, {}, options).pipe( // If successful, dispatch success action with result
                    map(() => {
                        return new SavePlanDetailComplete({planId: planId, errorStatus: null});
                    }
                ),
                // If request fails, dispatch success action with HTTP status code as part of payload
                catchError((error: any) => {
                    return of({type: AppActionTypes.SavePlanDetailComplete, payload: {planId: planId, errorStatus: error.status} });
                }));
        })
    ));

    convertPlan$: Observable<Action> = createEffect(() => this.actions$.pipe(
      ofType(AppActionTypes.ConvertPlan),
      map((action:any)=>action.payload),
      mergeMap((payload:any) => {
        const convertPlanUrl = environment.espEndpointBaseUrl + appSettings.resourceLocations.convertPlan(payload.planId);
        let headers = new HttpHeaders().append('esp-plan-sessionId', PlanHelper.getPlanDetailSessionId(payload.planId));
        let options = { headers: headers ,body:{
            fromStartDate:payload.fromStartDate,
            fromEndDate:payload.fromEndDate,
            forecastStartDate:payload.forecastStartDate,
            forecastEndDate:payload.forecastEndDate,
        }};
        return this.http.request('put',convertPlanUrl,options).pipe(
          map((resp)=>{

            return new ConvertPlanSuccess(resp)
          }),
          catchError((e)=>of({type:AppActionTypes.ConvertPlanFailed,payload:e}))
        )
      })
    ));

    deletePlanList$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(AppActionTypes.DeletePlans),
        map((action: any) => action.payload),
        mergeMap((payload: any) => {
            let planIdArr = payload;
            const planListUrl = environment.espEndpointBaseUrl + appSettings.resourceLocations.planSoftDelete;

            return this.http.request('patch', planListUrl, { body: { planIds: planIdArr } })
                .pipe(map((resp: any) => { return new DeletePlansSuccess(resp); }),
                    catchError(() => of({ type: AppActionTypes.DeletePlansFailed, payload: null }))
                );

        }

        )));

    // sync cache after refreshing token
    refrestPlanDetailCache$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(AppActionTypes.RefreshedTokenReceived),
        map((action: RefreshPlanCacheRequest) => action.payload),
        mergeMap((payload: any) => {
            const planSessions = PlanHelper.getPlanSessions();

            if(!planSessions){
                return of({type: AppActionTypes.CacheSyncFailure, payload: null});;
            }
            let resource = appSettings.resourceLocations.refreshCache;
            const planDetailUrl = environment.espEndpointBaseUrl + resource;
            return this.http.put(planDetailUrl, {"plans": planSessions})
                .pipe(
                    // If successful, dispatch success action with result
                    map((data: any) => {
                        return new CacheSyncSuccess(data);
                    }),
                    // If request fails, dispatch failed action
                    catchError((error: any) => {
                        console.log('CacheSyncFailure: ' + error);
                        return of({type: AppActionTypes.CacheSyncFailure, payload: error});

                    }),
                )
        })
    ));

    saveAsPlan$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(AppActionTypes.SaveAsPlan),
        map((action: any) => action.payload),
        mergeMap((payload: any) => {
            const url = environment.espEndpointBaseUrl + appSettings.resourceLocations.saveAs;
            let headers = new HttpHeaders().append('esp-plan-sessionId', PlanHelper.getPlanDetailSessionId(payload.planId));
            const respObs: any = "response";
            let options = {
                headers: headers, observe: respObs
            };
            let body = {
                copyFromPlanId: payload.planId,
                planName: payload.planName
            };
            console.debug("Save As plan, HTTP POST: " + url);

            return this.http.post(url, body, options)
                .pipe(
                    map((response: any) => {
                        console.debug("Save As HTTP status: " + response.status);
                        return new SaveAsPlanReceived(response);
                    }),
                    catchError((error: any) => {
                        console.log("SaveAsPlanFailed", error);
                        return of({type: AppActionTypes.SaveAsPlanFailed, payload: error});
                    })
                );
        })
    ));

    saveAsPlanSuccess$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(AppActionTypes.SaveAsPlanSuccess),
        map((action: any) => action.payload),
        mergeMap((payload: any) => {
            return of({type: AppActionTypes.SaveAsPlanSuccessReceived, payload: payload});
        })
    ));

    planNameExits$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(AppActionTypes.CheckPlanNameExists),
        map((action: any) => action.payload),
        mergeMap((payload: any) => {
            const origPlanName = payload.planName;
            const url = environment.espEndpointBaseUrl + appSettings.resourceLocations.planExists(origPlanName);
            const respObs: any = "response";
            const httpOptions = {
                observe: respObs
            };
            // console.debug("Check plan name exists via HTTP HEAD call: " + url);

            return this.http.head(url, httpOptions)
                .pipe(
                    map((response: any) => {
                        let result = new PlanNameExists();
                        result.planName = origPlanName;
                        result.exists = false;

                        if (response.status === 200) {
                            result.exists = true;
                        } else if (response.status != 204) {
                            console.debug("Plan exists unexpected HTTP status code: " + response.status);
                        }

                        return new CheckPlanNameExistsReceived(result);
                    }),
                    catchError((error: any) => {
                        console.log("CheckPlanNameExistsFailed", error);
                        return of({type: AppActionTypes.CheckPlanNameExistsFailed, payload: error});
                    })
                );
        })
    ));

    musMusets$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(AppActionTypes.GetMUsMUSets),
        map((action: any) => action.payload),
        mergeMap((payload: any) => {
            const muAndmusetUrl = environment.espEndpointBaseUrl + appSettings.resourceLocations.muAndMuset;
            let urlParameters = encodeURI(Object.entries(payload).map(e => e.join("=")).join("&"));
            return this.http.get(muAndmusetUrl + `?${urlParameters}`)
                .pipe(
                    map((data: any) => {
                        return (new MuAndMusetReceived(data))
                    }),
                    catchError((e) => of({ type: AppActionTypes.MuAndMusetRetrieveError, payload: e })),
                )
        })
    ));

  muStaffing$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AppActionTypes.GetMuStaffing),
    map((action: GetMuStaffing) => action.payload),
    mergeMap((payload: any) => {
      const planOid = encodeURI(payload.planId);
      let resource = appSettings.resourceLocations.muStaffing(planOid);
      let planDetailUrl = environment.espEndpointBaseUrl + resource;

      let headers = new HttpHeaders().append('esp-plan-sessionId', PlanHelper.getPlanDetailSessionId(planOid));
      let options = { headers: headers };

      let urlParameters = encodeURI("mu-oid=" + (payload.mu ? payload.mu:""));
      let url = planDetailUrl + `?${urlParameters}`;

      return this.http.get(url, options)
        .pipe(
          // If successful, dispatch success action with result
          map((data: MuStaffingResponse) => {

              return new GetMuStaffingSuccess(data);
            }
          ),
          // If request fails, dispatch failed action
          catchError((error:any) => {
            if(error.status === 410){
              return of({ type: AppActionTypes.GetMuStaffingFailed, payload: null });
            }
            return of({ type: AppActionTypes.GetMuStaffingFailed, payload: null });
          }),
        )
    })
  ));

    createPlan$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(AppActionTypes.CreatePlan),
        //map((action: any) => {payload:action.payload, version: action.version}),
        mergeMap((action:any) => {
            let payload = action.payload;
            let url = environment.espEndpointBaseUrl + appSettings.resourceLocations.planListV2;

            return this.http.post(url, payload).pipe(
                map((response: any) => {
                    return new CreatePlanSubmitted(response);
                }),
                catchError((error: any) => {
                    console.log("Create Plan Failed: ", error);
                    return of({type: AppActionTypes.CreatePlanError, payload: error});
                })
            );

        })
    ));

    editMUtoCTDistributionV2$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(AppActionTypes.EditMUDistV2),
        map((action: EditMUDistV2) => action.payload),
        mergeMap((payload: EditMuDistRequestV2) => {
            const planOid = encodeURI(payload.planId);
            let resource = appSettings.resourceLocations.muStaffing(planOid,2);
            let url = environment.espEndpointBaseUrl + resource;

            let headers = new HttpHeaders().append('esp-plan-sessionId', PlanHelper.getPlanDetailSessionId(planOid));
            let options = { headers: headers };
            const requestObj = {
              muOid:payload.muOid,
              base:payload.base,
              applyBase:payload.applyBase,
              dateDistributions:payload.dateDistributions,
              applyBaseToDates: payload.applyBaseToDates
            }
            return this.http.put(url, requestObj, options).pipe(
                map((data: any) => new EditMUDistSuccess({response: data, request: payload})),
                catchError((e) => of({ type: AppActionTypes.EditMUDistError,
                    payload: { error: e, request: payload } }))
            );
        })
    ));

    discardMuDistribution$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(AppActionTypes.DiscardMUDist),
        map((action: DiscardMUDist) => action.payload),
        mergeMap((payload: DiscardMuDistRequest) => {
            const planOid = encodeURI(payload.planId);
            let resource = appSettings.resourceLocations.muStaffing(planOid);
            let url = environment.espEndpointBaseUrl + resource;

            let headers = new HttpHeaders().append('esp-plan-sessionId', PlanHelper.getPlanDetailSessionId(planOid));
            let options = { headers: headers };
            const requestObj = {
                muOid: payload.muOid,
                revertAction: payload.revertAction,
                revertAll: payload.revertAll
            }
            // console.log(requestObj);

            return this.http.patch(url, requestObj, options).pipe(
                map((data: DiscardMuStffingResponse) => {
                  return new DiscardMUDistSuccess(data);
                }),
                catchError((error) => {
                  return of({ type: AppActionTypes.DiscardMUDistError, payload: error });
                })
            );
        })
    ));

  applyStaffingData$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AppActionTypes.ApplyStaffingData),
    mergeMap((action: ApplyStaffingData) => {
      let payload = action.payload;

      const planOid = encodeURI(payload.planId);
      let resource = appSettings.resourceLocations.applyStaffying(planOid);
      let planDetailUrl = environment.espEndpointBaseUrl + resource;

      let headers = new HttpHeaders().append('esp-plan-sessionId', PlanHelper.getPlanDetailSessionId(planOid));
      let options = { headers: headers };


      return this.tasksService.createTask("POST",planDetailUrl,options).pipe(
        map((a)=>{
          return new ApplyStaffingDataSuccess(a)
        }),
        catchError((e:any)=>{
          return of(new ApplyStaffingDataError(e,e.taskStatus));
        })
      )
    })
  ));

  applyMuParams$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AppActionTypes.ApplyMuParams),
    mergeMap((action: ApplyMuParams) => {
      let payload = action.payload;

      const planOid = encodeURI(payload.planId);
      let resource = appSettings.resourceLocations.applyMuParams(planOid);
      let applyMuParamsUrl = environment.espEndpointBaseUrl + resource;

      let headers = new HttpHeaders().append('esp-plan-sessionId', PlanHelper.getPlanDetailSessionId(planOid));
      let options = { headers: headers };

      return this.tasksService.createTask("POST", applyMuParamsUrl, options).pipe(
        map((a) => {
          return new ApplyMuParamsSuccess(a)
        }),
        catchError((e:any) => {
          return of(new ApplyMuParamsError(e, e.taskStatus));
        })
      )
    })
  ));

  savePlanSettings$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AppActionTypes.SavePlanSettings),
    mergeMap((action: SavePlanSettings) => {
      let payload = action.payload;

      const planOid = encodeURI(payload.planId);
      let resource = appSettings.resourceLocations.savePlanSettings(planOid);
      let requestUrl = environment.espEndpointBaseUrl + resource;

      let settings = {
        ctDistributionCalcMethod: payload.planSettings.ctDistributionCalcMethod,
        medianPatienceTime: payload.planSettings.medianPatienceTime == null ? null : +payload.planSettings.medianPatienceTime
      }

      let headers = new HttpHeaders().append('esp-plan-sessionId', PlanHelper.getPlanDetailSessionId(planOid));
      let options = { headers: headers, body: settings };

      return this.tasksService.createTask("POST",requestUrl,options).pipe(
        map((a)=>{
          return new SavePlanSettingsSuccess(a)
        }),
        catchError((e:any)=>{
          return of(new SavePlanSettingsError({
            planSettings: payload.planSettings,
            error:e
          },e.taskStatus));
        })
      )
    })
  ));

  applyFTERequired$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AppActionTypes.ApplyFTERequired),
    mergeMap((action: ApplyFTERequired) => {
      let payload = action.payload;

      const planOid = encodeURI(payload.planId);
      let resource = appSettings.resourceLocations.applyFTERequired(planOid);
      let requestUrl = environment.espEndpointBaseUrl + resource;

      let headers = new HttpHeaders().append('esp-plan-sessionId', PlanHelper.getPlanDetailSessionId(planOid));
      let options = { headers: headers };

      return this.tasksService.createTask("POST",requestUrl,options).pipe(
        map((a)=>{
          return new ApplyFTERequiredSuccess(a)
        }),
        catchError((e:any)=>{
          return of(new ApplyFTERequiredError({
            planSettings: payload.planSettings,
            error:e
          },e.taskStatus));
        })
      )
    })
  ));

  editEntitySettings$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AppActionTypes.EditEntitySettings),
    mergeMap((action: EditEntitySettings) => {
      let payload: EditEntitySettingsDto = action.payload;

      let resource = appSettings.resourceLocations.entitySettings;
      let url = environment.espEndpointBaseUrl + resource;
      let headers = new HttpHeaders().append('esp-plan-sessionId', PlanHelper.getPlanDetailSessionId(payload.planId));
      let options = { headers: headers };

      return this.http.post(url, payload, options).pipe(
        switchMap((data: any) => [
          new EditEntitySettingsSuccess(payload),
          new EditPlanDetailReceived({...data,request:payload}),
        ]),
        catchError((error: any) => {
            console.log("EditEntitySettingsFailed", error);
            return of({type: AppActionTypes.EditEntitySettingsError, payload: error});
        })
      );
    })
  ));

  exportEveryCt$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AppActionTypes.ExportEveryCt),
    map((action: any) => action.payload),
    mergeMap((payload: any) => {
      const planOid = encodeURI(payload.planId);
      const params = {interval: payload.interval};
      let urlParameters = encodeURI(Object.entries(params).map(e => e.join("=")).join("&"));
      const exportEveryCtUrl = environment.espEndpointBaseUrl
        + appSettings.resourceLocations.exportEveryCt(planOid) + `?${urlParameters}`;
      let headers = new HttpHeaders().append('esp-plan-sessionId', PlanHelper.getPlanDetailSessionId(planOid));
      return this.http.get(exportEveryCtUrl, { headers, responseType: 'blob' as 'json', observe: "response" as 'body'})
        .pipe( map( (data:any) => {
          return new ExportEveryCtSuccess({request: payload, body: data.body});
        }),
          catchError( (err) => of({type: AppActionTypes.ExportEveryCtFailed, payload: err}))
        )
    })
  ));

  multiEditPlanDetail$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AppActionTypes.MultiEditPlanDetail),
    mergeMap((action: MultiEditPlanDetail) => {
        let payload = action.payload;

        const url = environment.espEndpointBaseUrl + appSettings.resourceLocations.multiEditPlanDetails;

        let headers = new HttpHeaders().append('esp-plan-sessionId', PlanHelper.getPlanDetailSessionId(payload.planId.toString()));
        let options = { headers: headers,body:payload };

        return this.tasksService.createTask("PUT", url, options).pipe(
          map((data) => {
            data.interval = payload.editInterval;
            data.planId = payload.planId;
            data.request = payload;
            return new EditPlanDetailReceived(data);
          }),
          catchError((e)=>{
            return of({ type: AppActionTypes.EditPlanDetailError, payload: {error:e, request:payload} });
          }))
      }
    )));


  loadPlanDetailV2$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AppActionTypes.LoadPlanDetailV2),
    mergeMap((action: LoadPlanDetailV2) => {
        let payload = action.payload;
        const planOid = encodeURI(payload.planId);
        let resource = appSettings.resourceLocations.loadPlanDetailV2(planOid);
        let planDetailUrlV2 = environment.espEndpointBaseUrl + resource;
        if (payload.oid) {
          planDetailUrlV2 += "/" + payload.oid;
        }

        let headers = new HttpHeaders().append('esp-plan-sessionId', PlanHelper.getPlanDetailSessionId(planOid));
        let options = {headers: headers};


        return this.tasksService.createTask("GET", planDetailUrlV2, options).pipe(
          map((data) => {
            data.interval = payload.interval;
            data.planId = planOid;
            return new PlanDetailReceivedV2(data)
          }),
          catchError((error)=>{
            if(error.status === 410){
              return of({ type: AppActionTypes.PlanNotExist, payload: null });
            }
            return of({ type: AppActionTypes.PlanDetailReceivedV2, payload: null });
          }))
      }
    )));

  planDetailV2$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AppActionTypes.GetPlanDetailV2),
    map((action: PlanDetailRequest) => action.payload),
    mergeMap((payload: any) => {

      const planOid = encodeURI(payload.planId);
      let resource = appSettings.resourceLocations.planDetailV2(planOid);
      let planDetailV2Url = environment.espEndpointBaseUrl + resource;
      if(payload.oid){
        planDetailV2Url+="/"+payload.oid;
      }

      let headers = new HttpHeaders().append('esp-plan-sessionId', PlanHelper.getPlanDetailSessionId(planOid));
      let options = { headers: headers };
      const body = {
        interval: payload.interval,
        muRollup: payload.muRollup,
        planLevel: payload.planLevel,
        ctOids:payload.ctOids,
        muOids:payload.muOids
      };

      return this.http.post(planDetailV2Url, body,options)
        .pipe(
          // If successful, dispatch success action with result
          map((data: PlanDetailResponse) => {
              data.interval = payload.interval;
              data.planId = planOid;
              data.selectedType = payload.entity_type;
              data.selectedOid = payload.oid;

              /*data.entityType = "EG"
              data.ctInfo = [
                {id:'1000',name:'CT: Red',oid:'200-200-200'},
                {id:'2000',name:'CT: Green',oid:'300-200-200'},
                {id:'3000',name:'CT: Blue',oid:'400-200-200'},
                {id:'4000',name:'CT: Orange',oid:'500-200-200'},
                {id:'5000',name:'CT: Yellow',oid:'600-200-200'},
                {id:'6000',name:'CT: Black',oid:'700-200-200'},
                ]*/
              return new PlanDetailReceivedV2(data);
            }
          ),
          // If request fails, dispatch failed action
          catchError((error:any) => {
            if(error.status === 410){
              return of({ type: AppActionTypes.PlanNotExist, payload: null });
            }
            return of({ type: AppActionTypes.PlanDetailReceivedV2, payload: null });

          }),
        )
    })
  ));

  multiEditPlanDetailV2$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AppActionTypes.MultiEditPlanDetailV2),
    mergeMap((action: MultiEditPlanDetailV2) => {
        let payload = action.payload;

        const url = environment.espEndpointBaseUrl + appSettings.resourceLocations.multiEntityEditPlanDetails;

        let headers = new HttpHeaders().append('esp-plan-sessionId', PlanHelper.getPlanDetailSessionId(payload.planId.toString()));
        let options = { headers: headers,body:payload };

        return this.tasksService.createTask("PUT", url, options).pipe(
          map((data) => {
            data.interval = payload.editInterval;
            data.planId = payload.planId;
            data.request = payload;
            return new EditPlanDetailReceived(data);
          }),
          catchError((e)=>{
            return of({ type: AppActionTypes.EditPlanDetailError, payload: {error:e, request:payload} });
          }))
      }
    )));

  getCTSettings$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AppActionTypes.GetCTSettings),
    map((action: GetCTSettings) => action.payload),
    mergeMap((payload: any) => {
      let apiUrl = environment.espEndpointBaseUrl + appSettings.resourceLocations.ctSettings(payload.planId);
      let headers = new HttpHeaders().append('esp-plan-sessionId', PlanHelper.getPlanDetailSessionId(payload.planId));
      let options = { headers: headers };

      return this.http.get(apiUrl, options)
        .pipe(
          map((response: any) => new GetCTSettingsSuccess(response)),
          catchError(err => of(new GetCTSettingsFailed(err)))
        )
    })
  ));

  applyCtSettings$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AppActionTypes.ApplyCTSettings),
    mergeMap((action: ApplyCTSettings) => {
        let payload = {...action.payload};
        let planId = payload.planId;
        delete payload.planId;

        let apiUrl = environment.espEndpointBaseUrl + appSettings.resourceLocations.ctSettings(planId);
        let headers = new HttpHeaders().append('esp-plan-sessionId', PlanHelper.getPlanDetailSessionId(planId));
        let options = { headers: headers,body:payload };

        return this.tasksService.createTask("PUT", apiUrl, options).pipe(
          map(() => {
            return new ApplyCTSettingsSuccess({planId,request:payload});
          }),
          catchError((e)=>{
            return of({ type: AppActionTypes.ApplyCTSettingsFailed, payload: {error:e, request:payload} });
          }))
      })
  ));
}
