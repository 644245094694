// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .wfm-modal .modal-body {
  min-width: 420px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/plan/new-hire-dialog-v2/new-hire-dialog-v2.component.scss","webpack://./../../___CICD%20JOBS___/Deploy%20ESP%20to%20devN/src/app/pages/plan/new-hire-dialog-v2/new-hire-dialog-v2.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;ACCF","sourcesContent":["::ng-deep .wfm-modal .modal-body{\n  min-width: 420px;\n}\n","::ng-deep .wfm-modal .modal-body {\n  min-width: 420px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
