// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p {
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon-wrapper:hover {
  color: #4f95ce;
}

.icon-wrapper {
  display: inline-flex;
  width: 24px;
  height: 24px;
  cursor: pointer;
  font-size: 20px;
  color: #93a4aa;
  margin-left: 8px;
  align-content: center;
  justify-content: center;
  position: relative;
  top: 3px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/plan/entity-type-field/entity-type-field.component.scss","webpack://./../../___CICD%20JOBS___/Deploy%20ESP%20to%20devN/src/app/pages/plan/entity-type-field/entity-type-field.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,uBAAA;ACCF;;ADIA;EACE,cAAA;ACDF;;ADGA;EACE,oBAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,eAAA;EACA,cAAA;EACA,gBAAA;EACA,qBAAA;EACA,uBAAA;EACA,kBAAA;EACA,QAAA;ACAF","sourcesContent":["p {\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n\n\n.icon-wrapper:hover{\n  color:#4f95ce;\n}\n.icon-wrapper{\n  display: inline-flex;\n  width: 24px;\n  height: 24px;\n  cursor: pointer;\n  font-size: 20px;\n  color: #93a4aa;\n  margin-left: 8px;\n  align-content: center;\n  justify-content: center;\n  position: relative;\n  top: 3px;\n}\n","p {\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.icon-wrapper:hover {\n  color: #4f95ce;\n}\n\n.icon-wrapper {\n  display: inline-flex;\n  width: 24px;\n  height: 24px;\n  cursor: pointer;\n  font-size: 20px;\n  color: #93a4aa;\n  margin-left: 8px;\n  align-content: center;\n  justify-content: center;\n  position: relative;\n  top: 3px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
