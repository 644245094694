// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p {
  overflow: hidden;
  text-overflow: ellipsis;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/plan/tooltip-field/tooltip-field.component.scss","webpack://./../../___CICD%20JOBS___/Deploy%20ESP%20to%20devN/src/app/pages/plan/tooltip-field/tooltip-field.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,uBAAA;ACCF","sourcesContent":["p {\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n","p {\n  overflow: hidden;\n  text-overflow: ellipsis;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
